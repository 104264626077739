<template>
  <v-container class="mt-n4">
    <v-row class="align-center justify-center">
      <v-btn-toggle
        v-model="settings.view"
        dense
        class="mr-2"
        mandatory
        v-if="type != 'course'"
      >
        <v-btn text value="list">Liste</v-btn>
        <v-btn text value="tab">Tabs</v-btn>
        <v-btn text value="day">Tag</v-btn>
        <v-btn text value="week">Woche</v-btn>
      </v-btn-toggle>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        text
        @click="settings.focus = today()"
        class="mr-2"
        v-if="type != 'course'"
        >Heute</v-btn
      >
      <v-btn
        outlined
        text
        class="mr-4"
        v-if="type == 'course'"
        @click="$vuetify.goTo('#today', { offset: 20 })"
        >Heute</v-btn
      >

      <DateInput
        v-model="settings.focus"
        class="shrink"
        :allowed-dates="allowedDates"
        v-if="type != 'course'"
      />
      <v-btn icon @click="prev()" v-if="type != 'course'">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn icon @click="next()" class="mr-2" v-if="type != 'course'">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-switch v-model="settings.showEvents" inset label="Termine"></v-switch>

      <v-switch
        v-if="$_hasRole('teacher')"
        class="ml-2"
        v-model="settings.showAbsences"
        inset
        label="Absenzen"
      ></v-switch>
    </v-row>
  </v-container>
</template>

<script>
import DateInput from "common/components/DateInput.vue";

import {
  findMonday,
  formatDayOfWeek,
  today,
  addDays,
} from "common/utils/date.js";
export default {
  name: "RegisterSettings",
  components: { DateInput },
  props: ["type"],
  data() {
    return {
      settings: {
        focus: "",
        showEvents: false,
        showAbsences: true,
        view: "list",
        ready: false,
      },
    };
  },
  computed: {
    steps() {
      return this.settings.view === "day" ? 1 : 7;
    },
  },
  watch: {
    settings: {
      handler() {
        if (this.settings.view !== "day" && this.settings.view !== "tab") {
          this.settings.focus = findMonday(addDays(this.settings.focus, 1));
        }
        window.localStorage.setItem(
          "RegisterSettings",
          JSON.stringify(this.settings)
        );

        this.$emit("input", this.settings);
      },
      deep: true,
    },
  },
  methods: {
    addDays,
    allowedDates(val) {
      return formatDayOfWeek(val) !== "So";
    },
    findMonday,
    today,
    prev() {
      this.settings.focus = this.addDays(this.settings.focus, -this.steps);
    },
    next() {
      this.settings.focus = this.addDays(this.settings.focus, this.steps);
    },
  },
  created() {
    if (window.localStorage.getItem("RegisterSettings")) {
      this.settings = JSON.parse(
        window.localStorage.getItem("RegisterSettings")
      );
    }
    if (!this.settings.focus) {
      this.settings.focus = today();
    }
    this.settings.ready = true;
  },
};
</script>
